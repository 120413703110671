import { AgGridReact } from "ag-grid-react";
import {
  CellClassParams,
  ColDef,
  Column,
  IAggFuncParams,
  ICellRendererParams,
  IRowNode,
  SetFilterModel,
  SideBarDef,
  ValueGetterParams,
} from "ag-grid-enterprise";
import { useLazyAsync } from "../../../../core/tools/Hooks";
import api from "../../../../core/api/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoaderComponent from "../../../../Components/Common/Loader";
import { formatFinancial } from "../../../../helpers/helpers";
import {
  PropsSQLDBStatusResponse,
  PropsSQLDBStatusResponseBOM,
  PropsSQLDBStatusResponseFinancial,
  PropsSQLDBStatusResponseRoles,
  SQL_DB_Status_Response,
} from "../../../../core/api/generated/conterra";
import { GetRowIdFunc } from "ag-grid-community/dist/types/core/entities/gridOptions";
import OpenCardLink from "../../../../Components/OpenCardLink";
import ViewsToolPanel from "../../SideBar/Views";
import StatusPanel_ReloadRows from "../../StatusBar/Reload";
import StatusPanel_ClearFilters from "../../StatusBar/ClearFilters";
import DataToolPanel, { DataToolPanelProps } from "../../SideBar/Data";
import { ISO_DATE_FORMAT } from "../../../../core/tools/formats";
import moment from "moment";
import styles from "../../aggrid.module.scss";
import { Chip } from "@progress/kendo-react-buttons";

function sumByBoolean(p: IAggFuncParams<SQL_DB_Status_Response>) {
  return p.values.reduce(
    (a, b) => a + (typeof b === "number" ? b : b ? 1 : 0),
    0
  );
}

enum COL_TYPES {
  currency = "currency",
  booleanForPivot = "booleanForPivot",
  booleanYesNo = "booleanYesNo",
  dateFormatted = "dateFormatted",
  dateTimeFormatted = "dateTimeFormatted",
  float = "float",
  integer = "integer",
  percentage = "percentage",
}

const getRowId: GetRowIdFunc<SQL_DB_Status_Response> = (p) => {
  return p.data.id.toString();
};

export default function AGGridStatusDashboard() {
  const [getMilestones, milestones] = useLazyAsync(api.sql.dbStatusMilestones);
  useEffect(() => getMilestones(), [getMilestones]);
  const MILESTONES_COLS = useMemo<ColDef[]>(() => {
    if (!milestones.data) return [];
    const columns: ColDef[] = [];
    milestones.data.forEach((ms) => {
      const lowerCamelCaseCode = ms.code[0].toLowerCase() + ms.code.slice(1);
      // Projected
      columns.push({
        colId: lowerCamelCaseCode + "_Projected",
        field: "milestones." + lowerCamelCaseCode + "_Projected",
        headerName: ms.name + " Projected",
        cellDataType: "dateString",
        type: COL_TYPES.dateFormatted,
      });
      // Actual
      columns.push({
        colId: lowerCamelCaseCode + "_Actual",
        field: "milestones." + lowerCamelCaseCode + "_Actual",
        headerName: ms.name + " Actual",
        cellDataType: "dateString",
        type: COL_TYPES.dateFormatted,
      });
      // Responsible
      columns.push({
        colId: lowerCamelCaseCode + "_ResponsibleName",
        field: "milestones." + lowerCamelCaseCode + "_ResponsibleName",
        headerName: ms.name + " Responsible",
      });
      // Comments
      columns.push({
        colId: lowerCamelCaseCode + "_Comments",
        field: "milestones." + lowerCamelCaseCode + "_Comments",
        headerName: ms.name + " Comments",
      });
    });
    return columns;
  }, [milestones]);

  const [getRowsMethod, rows] = useLazyAsync(api.sql.dbStatus);
  const [onDate, setOnDate] = useState<Date>();
  const getRows = useCallback(
    () =>
      getRowsMethod({
        onDate: onDate ? moment(onDate).format(ISO_DATE_FORMAT) : null,
      }),
    [getRowsMethod, onDate]
  );
  useEffect(getRows, [getRows]);

  const DEFAULT_COL_DEF = useMemo(
    () =>
      ({
        filter: true,
        floatingFilter: true,
        enableRowGroup: true,
        initialHide: true,
        enablePivot: true,
      } as ColDef),
    []
  );

  const COL_TYPES_DESCRIPTION = useMemo(
    () =>
      ({
        [COL_TYPES.percentage]: {
          width: 110,
          minWidth: 90,
          filter: "agNumberColumnFilter",
          cellRenderer: (p: any) => {
            if (isNaN(p.value) || p.value === null) {
              return "";
            }

            return (
              <div className={styles.CenterCellContent}>
                {p.value.toFixed(2)}%
              </div>
            );
          },
        },
        [COL_TYPES.float]: {
          width: 100,
          minWidth: 70,
          filter: "agNumberColumnFilter",
          aggFunc: "sum",
          cellRenderer: (p: any) => {
            if (isNaN(p.value) || p.value === null) {
              return "";
            }

            return (
              <div className={styles.CenterCellContent}>
                {p.value.toFixed(2)}
              </div>
            );
          },
        },
        [COL_TYPES.integer]: {
          width: 100,
          minWidth: 70,
          filter: "agNumberColumnFilter",
          aggFunc: "sum",
          cellRenderer: (p: any) => {
            if (isNaN(p.value) || p.value === null) {
              return "";
            }

            return <div className={styles.CenterCellContent}>{p.value}</div>;
          },
        },
        [COL_TYPES.currency]: {
          width: 150,
          minWidth: 100,
          filter: "agNumberColumnFilter",
          aggFunc: "sum",
          cellRenderer: (p: any) => {
            if (isNaN(p.value) || p.value === null) {
              return "";
            }

            return (
              <div className={styles.CurrencyCellContent}>
                <span>$</span>
                <span>{formatFinancial(p.value)}</span>
              </div>
            );
          },
          enableValue: true,
        },
        [COL_TYPES.dateFormatted]: {
          enableValue: true,
          width: 130,
          minWidth: 90,
          cellRenderer: (p: any) => {
            if (!p.value) return "";
            return (
              <div className={styles.CenterCellContent}>
                {moment(p.value).format("L")}
              </div>
            );
          },
        },
        [COL_TYPES.dateTimeFormatted]: {
          enableValue: true,
          width: 150,
          minWidth: 100,
          cellRenderer: (p: any) => {
            if (!p.value) return "";
            return (
              <div className={styles.CenterCellContent}>
                {moment(p.value).format("L LT")}
              </div>
            );
          },
        },
        [COL_TYPES.booleanForPivot]: {
          aggFunc: sumByBoolean,
          enableValue: true,
          width: 150,
          minWidth: 120,
          cellRendererSelector: (
            p: ICellRendererParams<SQL_DB_Status_Response>
          ) => {
            if (typeof p.value === "number")
              return {
                component: (
                  pp: ICellRendererParams<SQL_DB_Status_Response>
                ) => (
                  <div className={styles.CenterCellContent}>
                    <a
                      onClick={async () => {
                        if (!p.colDef?.colId) return;

                        let groupNode: IRowNode | null = p.node;
                        const filters: {
                          column: Column;
                          value: string | null;
                        }[] = [];
                        while (!!groupNode && groupNode.level >= 0) {
                          if (groupNode.rowGroupColumn) {
                            filters.push({
                              column: groupNode.rowGroupColumn,
                              value: groupNode.key,
                            });
                          }
                          groupNode = groupNode.parent;
                        }
                        filters.push({
                          column: p.column!,
                          value: "true",
                        });
                        p.api.updateGridOptions({ pivotMode: false });
                        p.api.setRowGroupColumns([]);
                        for (const filter of filters)
                          await p.api.setColumnFilterModel<SetFilterModel>(
                            filter.column,
                            {
                              filterType: "set",
                              values: [filter.value],
                            }
                          );
                        p.api.onFilterChanged();
                      }}
                    >
                      {pp.value}
                    </a>
                  </div>
                ),
              };
          },
        },
        [COL_TYPES.booleanYesNo]: {
          width: 100,
          minWidth: 80,
          valueGetter: (p: any) => {
            const field = p.colDef.field;
            const value = p.data?.[field];
            if (value === null || value === undefined) return null;
            return p.data?.[field] ? "Yes" : "No";
          },
          cellRenderer: (p: any) => {
            if (p.value === null) return "";
            return (
              <div className={styles.YesNoCellContent}>
                {p.value === "Yes" ? (
                  <span
                    className={"mdi mdi-check"}
                    style={{ color: "#0072c6", opacity: 0.8, fontSize: 18 }}
                  ></span>
                ) : (
                  <span
                    className={"mdi mdi-minus"}
                    style={{ color: "grey", opacity: 0.7, fontSize: 18 }}
                  ></span>
                )}
              </div>
            );
          },
        },
      } as Record<COL_TYPES, ColDef>),
    []
  );
  const CALCULATED_COLS = useMemo(
    () =>
      [
        {
          colId: "_SOEmpty",
          headerName: "No Sales Order",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.financial?.salesOrder,
        },
        {
          colId: "_BudgetNotApproved",
          headerName: "Budget not Approved",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.financial?.budgetApproved && !p.data?.excludeFromWIP,
        },
        {
          colId: "_BudgetNotApprovedHasSpend",
          headerName: `Budget Not Approved, Has Spend`,
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.financial?.budgetApproved &&
            (p.data?.financial?.totalSpend || 0) > 0 &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_BudgetNotApprovedHasRevenue",
          headerName: "Budget Not Approved, Has Revenue",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.financial?.budgetApproved &&
            (p.data?.financial?.submittedInvoices || 0) > 0 &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_BudgetNotEqualSalesOrder",
          headerName: "Budget doesn't equal Sales Order",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.financial?.soProjectedRevenueVariance &&
              !p.data?.excludeFromWIP
            );
          },
        },
        {
          colId: "_SpendExceedsBudget",
          headerName: "Spend Exceeds Budget",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !!p.data?.financial?.budgetApproved &&
            (p.data?.financial.totalSpend || 0) >
              (p.data?.financial.projectedSpend || 0) &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_RevenueExceedsBudget",
          headerName: "Revenue Exceeds Budget",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !!p.data?.financial?.budgetApproved &&
            (p.data?.financial.submittedInvoices || 0) >
              (p.data?.financial.projectedRevenue || 0) &&
            !p.data?.excludeFromWIP,
        },
        {
          colId: "_CXStartPending",
          headerName: "CX Start Pending",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          headerClass: `${styles.ArrowHeader} ${styles.ArrowHeaderBlack}`,
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) =>
            !p.data?.milestones?.constStart_Actual,
        },
        {
          colId: "_CXStartActualCXCompletePending",
          headerName: "CX Started and not Completed",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          headerClass: `${styles.ArrowHeader} ${styles.ArrowHeaderGreyPink}`,
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones?.constStart_Actual &&
              !p.data?.milestones.consCompl_Actual
            );
          },
        },
        {
          colId: "_CXCompleteActualCOPApprovedPending",
          headerName: "CX Completed, COP not Approved",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones?.consCompl_Actual &&
              !p.data?.milestones.copAppr_Actual
            );
          },
          headerClass: `${styles.ArrowHeader} ${styles.ArrowHeaderBlue}`,
        },
        {
          colId: "_COPApprovedRemainingToInvoice",
          headerName: "COP Approved, w/ Remaining to Invoice",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones?.copAppr_Actual &&
              (p.data?.financial?.remainingToInvoice || 0) > 0
            );
          },
          headerClass: `${styles.ArrowHeader} ${styles.ArrowHeaderGreenBlue}`,
        },
        {
          colId: "_COPApprovedNoRemainingToInvoice",
          headerName: "COP Approved, No Remaining to Invoice",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones?.copAppr_Actual &&
              !p.data?.financial?.remainingToInvoice
            );
          },
          headerClass: `${styles.ArrowHeader} ${styles.ArrowHeaderOrange}`,
        },
        {
          colId: "_COPApprovedReadyToClose",
          headerName: "COP Approved Ready to Close",
          type: COL_TYPES.booleanForPivot,
          cellDataType: "boolean",
          valueGetter: (p: ValueGetterParams<SQL_DB_Status_Response>) => {
            return (
              !!p.data?.milestones?.copAppr_Actual &&
              !p.data?.bom?.opened &&
              !p.data?.financial?.vimPoBalance &&
              !p.data?.financial?.remainingToInvoice
            );
          },
          headerClass: `${styles.ArrowHeader} ${styles.ArrowHeaderRed}`,
        },
      ] as ColDef<SQL_DB_Status_Response>[],
    []
  );

  const COL_DEF = useMemo(
    () =>
      [
        {
          colId: PropsSQLDBStatusResponse.rowNum,
          field: PropsSQLDBStatusResponse.rowNum,
          headerName: "#",
          pinned: true,
          width: 70,
          filter: false,
          suppressHeaderMenuButton: true,
          initialHide: false,
          aggFunc: "count",
        },
        {
          colId: PropsSQLDBStatusResponse.number,
          field: PropsSQLDBStatusResponse.number,
          headerName: "Build Plan",
          width: 300,
          initialHide: false,
          pinned: true,
          cellRenderer: (p: ICellRendererParams<SQL_DB_Status_Response>) => (
            <OpenCardLink
              text={p.value}
              refName={"FSMBuildPlans"}
              dataAttr={p.data?.[PropsSQLDBStatusResponse.id]}
            />
          ),
        },
        {
          colId: PropsSQLDBStatusResponse.active,
          field: PropsSQLDBStatusResponse.active,
          headerName: "BP State",
          width: 100,
          suppressHeaderMenuButton: true,
          initialHide: false,
          type: COL_TYPES.booleanForPivot,
          filterParams: {
            valueFormatter: (p: any) => {
              return p.value ? "Active" : "Closed";
            },
          },
          cellRenderer: (p: any) => {
            return (
              <Chip
                themeColor={
                  p.data?.[p.column.colDef.field] ? "success" : "error"
                }
                text={p.data?.[p.column.colDef.field] ? "Active" : "Closed"}
                icon={"circle"}
              />
            );
          },
        },
        {
          field: PropsSQLDBStatusResponse.project,
          headerName: "Project",
          initialHide: false,
          width: 130,
        },
        {
          field: PropsSQLDBStatusResponse.scenario,
          headerName: "Scenario",
          width: 130,
        },
        {
          field: PropsSQLDBStatusResponse.region,
          headerName: "Region",
          width: 130,
        },
        {
          field: PropsSQLDBStatusResponse.market,
          headerName: "Market",
          initialHide: false,
          width: 130,
        },
        {
          field: PropsSQLDBStatusResponse.customer,
          headerName: "Customer",
          width: 140,
        },
        {
          field: PropsSQLDBStatusResponse.customerJob,
          headerName: "Customer Job",
          width: 260,
        },
        {
          field: PropsSQLDBStatusResponse.carrier,
          headerName: "Carrier",
          width: 130,
        },
        {
          field: PropsSQLDBStatusResponse.class,
          headerName: "Class",
          width: 130,
        },
        {
          field: PropsSQLDBStatusResponse.category,
          headerName: "Category",
          width: 200,
        },
        {
          field: PropsSQLDBStatusResponse.site,
          headerName: "Site ID",
          cellRenderer: (p: ICellRendererParams<SQL_DB_Status_Response>) => (
            <OpenCardLink
              text={p.value}
              refName={"FSMSites"}
              dataAttr={p.data?.[PropsSQLDBStatusResponse.siteId]}
            />
          ),
        },
        {
          field: PropsSQLDBStatusResponse.siteAddress,
          headerName: "Site Address",
          width: 200,
        },
        {
          field: PropsSQLDBStatusResponse.owner,
          headerName: "Build Plan Owner",
          initialHide: false,
        },
        {
          field: PropsSQLDBStatusResponse.profitCenter,
          headerName: "Profit Center",
        },
        {
          field: `${PropsSQLDBStatusResponse.roles}.${PropsSQLDBStatusResponseRoles.crewLead}`,
          headerName: "Crew Lead",
        },
        {
          field: `${PropsSQLDBStatusResponse.roles}.${PropsSQLDBStatusResponseRoles.copLead}`,
          headerName: "Cop Lead",
        },
        {
          colId: PropsSQLDBStatusResponse.permitStatus,
          field: PropsSQLDBStatusResponse.permitStatus,
          headerName: "Permit Status",
        },
        {
          colId: PropsSQLDBStatusResponse.permitStatusComment,
          field: PropsSQLDBStatusResponse.permitStatusComment,
          headerName: "Permit Status Comment",
        },
        {
          colId: PropsSQLDBStatusResponse.cxStatus,
          field: PropsSQLDBStatusResponse.cxStatus,
          headerName: "CX Status",
        },
        {
          colId: PropsSQLDBStatusResponse.cxStatusComment,
          field: PropsSQLDBStatusResponse.cxStatusComment,
          headerName: "CX Status Comment",
        },
        {
          field: PropsSQLDBStatusResponse.started,
          headerName: "Started",
          cellDataType: "dateString",
          initialHide: false,
          type: COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.salesOrder}`,
          headerName: "Sales Order Total",
          headerTooltip: "Total of all Sales Orders in Build Plan",
          type: COL_TYPES.currency,
          initialHide: false,
          aggFunc: "sum",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.lastQuoteDate}`,
          headerName: "Sales Order Date",
          cellDataType: "dateString",
          type: COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.lastQuoteSubmitted}`,
          headerName: "Submitted to Customer",
          cellDataType: "dateString",
          type: COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.lastPODate}`,
          headerName: "PO Received",
          cellDataType: "dateString",
          type: COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.unallocatedCustomerPO}`,
          headerName: "Unallocated Customer PO Total",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.allocatedCustomerPO}`,
          headerName: "Allocated Customer PO Total",
          type: COL_TYPES.currency,
          initialHide: false,
          aggFunc: "sum",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.sopoVariance}`,
          headerName: "Sales Order & PO Variance",
          headerTooltip: "SO Total - Unallocated PO Total - Allocated PO Total",
          type: COL_TYPES.currency,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.soProjectedRevenueVariance}`,
          headerName: "Sales Order and Budget Revenue Variance",
          headerTooltip: "Sales Order Total - Budget Total Revenue",
          type: COL_TYPES.currency,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.projectedRevenue}`,
          headerName: "Budget Total Revenue",
          headerTooltip: "Total Planned Revenue from Budget Dashboard",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.budgetStatus}`,
          headerName: "Budget Status",
          initialHide: false,
          width: 130,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.budgetApproved}`,
          headerName: "Budget Approved",
          cellDataType: "dateString",
          type: COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.bom}.${PropsSQLDBStatusResponseBOM.initialSent}`,
          headerName: "BOM Sent to Warehouse",
          cellDataType: "dateString",
          type: COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.bom}.${PropsSQLDBStatusResponseBOM.total}`,
          headerName: "BOM Total",
          headerTooltip: "Total of All BOMs Amount Associated to Build Plan",
          type: COL_TYPES.currency,
        },
        {
          field: `${PropsSQLDBStatusResponse.bom}.${PropsSQLDBStatusResponseBOM.kitted}`,
          headerName: "Kitted to Date",
          headerTooltip: "Total of All Kitted BOMs",
          type: COL_TYPES.currency,
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            const data = p.data?.bom;
            if (!data?.kitted || !data?.total) return;
            if (data.kitted > data.total) return { color: "red" };
            return;
          },
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.budgetHrs}`,
          headerName: "Budgeted Field Labor Hours",
          headerTooltip: "Total Planned Labor Hours from Budget Dashboard",
          type: COL_TYPES.float,
          initialHide: false,
          width: 140,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.hoursConsumed}`,
          headerName: "Hours Consumed",
          headerTooltip: "Total Spend Labor Hours Allocated to Build Plan",
          type: COL_TYPES.float,
          initialHide: false,
          width: 130,
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            const consumedHours = p.value || 0;
            const budgetHours =
              p.data?.[PropsSQLDBStatusResponse.financial]?.[
                PropsSQLDBStatusResponseFinancial.budgetHrs
              ] || 0;
            if (consumedHours > budgetHours) return { color: "red" };
          },
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.hoursRemaining}`,
          headerName: "Remaining Hours to Complete",
          headerTooltip: "Budgeted Field Labor Hours - Hours Consumed",
          initialHide: false,
          type: COL_TYPES.float,
          width: 140,
        },
        {
          field: PropsSQLDBStatusResponse.pwByDefault,
          headerName: "PW by default",
          initialHide: false,
          type: COL_TYPES.booleanYesNo,
        },
        {
          field: PropsSQLDBStatusResponse.pwLastUpdate,
          headerName: "PW last update",
          cellDataType: "dateString",
          type: COL_TYPES.dateTimeFormatted,
        },
        {
          field: PropsSQLDBStatusResponse.completion,
          headerName: "Estimated Completion",
          headerTooltip: "% as Noted in Build Plan",
          initialHide: false,
          type: COL_TYPES.percentage,
          width: 140,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.projectedSpend}`,
          headerName: "Projected Spend",
          headerTooltip: "Current Planned Spend from Budget Dashboard",
          type: COL_TYPES.currency,
          initialHide: false,
        },
        {
          field: `${PropsSQLDBStatusResponse.bom}.${PropsSQLDBStatusResponseBOM.opened}`,
          headerName: "Open BOMs",
          type: COL_TYPES.integer,
          width: 130,
          minWidth: 100,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.vimPoBalance}`,
          headerName: "VIM PO Balance",
          type: COL_TYPES.float,
          width: 130,
          minWidth: 100,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.totalSpend}`,
          headerName: "Total Spend to Date",
          headerTooltip: "Total Actual Spend from Budget Dashboard",
          type: COL_TYPES.currency,
          initialHide: false,
          aggFunc: "sum",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.totalSpendPercent}`,
          headerName: "% of Budgeted Spend",
          headerTooltip: "%, Total Spend to Date/Projected Spend",
          width: 135,
          type: COL_TYPES.percentage,
          initialHide: false,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.remainingSpend}`,
          headerName: "Remaining Spend",
          headerTooltip: "Projected Spend - Total Spend To Date",
          type: COL_TYPES.currency,
          aggFunc: "sum",
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            if (p.value < 0) return { color: "red" };
            return;
          },
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.submittedInvoices}`,
          headerName: "Total Invoiced to Date",
          headerTooltip: "Total of Receivable and Paid Total from Sales Orders",
          type: COL_TYPES.currency,
          initialHide: false,
          aggFunc: "sum",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.lastInvoiceDate}`,
          headerName: "Invoice Submitted",
          cellDataType: "dateString",
          type: COL_TYPES.dateFormatted,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.remainingToInvoice}`,
          headerName: "Remaining to Invoice",
          headerTooltip: "Total of Remaining To Bill in Sales Orders",
          type: COL_TYPES.currency,
          initialHide: false,
          aggFunc: "sum",
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.readyToBill}`,
          headerName: "Ready To Bill",
          type: COL_TYPES.currency,
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.projectedBillingDate}`,
          headerName: "Projected Billing Date",
          cellDataType: "dateString",
          type: COL_TYPES.dateFormatted,
          cellStyle: (p: CellClassParams<SQL_DB_Status_Response>) => {
            const financialData = p.data?.[PropsSQLDBStatusResponse.financial];
            if (!financialData) return;
            const projectedBillingDate = moment(
              financialData[
                PropsSQLDBStatusResponseFinancial.projectedBillingDate
              ]
            ).toDate();
            const remainingToInvoiceDate = moment(
              financialData[
                PropsSQLDBStatusResponseFinancial.remainingToInvoice
              ]
            ).toDate();
            if (
              projectedBillingDate < new Date() &&
              remainingToInvoiceDate.valueOf() > 0
            ) {
              return { color: "red" };
            }
          },
        },
        {
          field: `${PropsSQLDBStatusResponse.financial}.${PropsSQLDBStatusResponseFinancial.wip}`,
          headerName: "WIP",
          headerTooltip:
            "Variance Under Spend - Variance Under Billed, as Calculated in WIP Dashboard",
          initialHide: false,
          type: COL_TYPES.currency,
          aggFunc: "sum",
        },
        {
          field: PropsSQLDBStatusResponse.excludeFromWIP,
          headerName: "Exclude from WIP",
          width: 120,
          initialHide: false,
          type: COL_TYPES.booleanYesNo,
        },
        {
          headerName: "Calculated Indicators",
          children: CALCULATED_COLS,
        },
        {
          headerName: "Milestones",
          children: MILESTONES_COLS,
        },
      ] as ColDef<SQL_DB_Status_Response>[],
    [CALCULATED_COLS, MILESTONES_COLS]
  );

  const sideBar = useMemo<SideBarDef>(() => {
    return {
      toolPanels: [
        {
          id: "data",
          labelDefault: "Data",
          labelKey: "data",
          iconKey: "grid",
          toolPanel: DataToolPanel,
          toolPanelParams: {
            onDateChange: setOnDate,
          } as DataToolPanelProps,
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "views",
          labelDefault: "Views",
          labelKey: "views",
          iconKey: "pivot",
          toolPanel: ViewsToolPanel,
          toolPanelParams: {
            ownerStatsColIds: [
              PropsSQLDBStatusResponse.active,
              ...CALCULATED_COLS.map((x) => x.colId!),
            ],
            milestonesStatsColIds: [
              PropsSQLDBStatusResponse.number,
              ...MILESTONES_COLS.map((x) => x.colId!),
              PropsSQLDBStatusResponse.permitStatus,
              PropsSQLDBStatusResponse.permitStatusComment,
              PropsSQLDBStatusResponse.cxStatus,
              PropsSQLDBStatusResponse.cxStatusComment,
            ],
          },
        },
      ],
      defaultToolPanel: "",
    };
  }, [CALCULATED_COLS, setOnDate, MILESTONES_COLS]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        {
          statusPanel: StatusPanel_ClearFilters,
          align: "left",
        },
        {
          statusPanel: () => {
            if (!onDate) return <span></span>;
            return (
              <div className="ag-status-name-value">
                <span className="component">
                  {`Data on ${moment(onDate).format("L")}`}
                </span>
              </div>
            );
          },
          align: "left",
        },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
        {
          statusPanel: StatusPanel_ReloadRows,
          align: "right",
          statusPanelParams: {
            getRows,
          },
        },
      ],
    };
  }, [getRows, onDate]);

  if (milestones.loading) return <LoaderComponent />;

  return (
    <div
      className={"ag-theme-quartz"}
      style={{ width: "100%", height: "100%" }}
    >
      <AgGridReact
        sideBar={sideBar}
        statusBar={statusBar}
        getRowId={getRowId}
        rowData={rows.data}
        columnTypes={COL_TYPES_DESCRIPTION}
        columnDefs={COL_DEF}
        loading={rows.loading}
        defaultColDef={DEFAULT_COL_DEF}
        enableCharts={true}
        grandTotalRow={"bottom"}
        suppressAggFuncInHeader={true}
        pivotPanelShow={"onlyWhenPivoting"}
        rowGroupPanelShow={"onlyWhenGrouping"}
        cellSelection={true}
      />
    </div>
  );
}
